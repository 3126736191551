var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","fullscreen":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('app.pagos.rent_buy_equipment'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-list',{attrs:{"three-line":""}},[(_vm.hideElements.length === 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.conceptos_pago.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.conceptos_pago.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.conceptos_pago.single'),"items":_vm.conceptos,"item-text":"descripcion","item-value":"id","error-messages":errors,"required":"","clearable":""},model:{value:(_vm.item.concepto_id),callback:function ($$v) {_vm.$set(_vm.item, "concepto_id", $$v)},expression:"item.concepto_id"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.currentConcepto)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.discount')))]),_c('v-list-item-subtitle',[_c('v-text-field',{staticClass:"mt-2",attrs:{"placeholder":_vm.$t('app.headers.discount'),"clearable":"","reverse":"","outlined":"","type":"number"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.discountType),callback:function ($$v) {_vm.discountType=$$v},expression:"discountType"}},[_c('v-btn',{attrs:{"small":"","active-class":"primary"}},[_vm._v(" $ ")]),_c('v-btn',{attrs:{"small":"","active-class":"primary"}},[_vm._v(" % ")])],1)]},proxy:true}],null,true),model:{value:(_vm.discountAmount),callback:function ($$v) {_vm.discountAmount=$$v},expression:"discountAmount"}})],1)],1)],1):_vm._e(),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.formas_pago.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.formas_pago.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.formas_pago.single'),"items":_vm.formasPago,"item-text":"nombre","item-value":"id","error-messages":errors,"required":"","clearable":""},model:{value:(_vm.item.forma_pago_id),callback:function ($$v) {_vm.$set(_vm.item, "forma_pago_id", $$v)},expression:"item.forma_pago_id"}})]}}],null,true)})],1)],1)],1),(_vm.showSelectorJugadores && !_vm.hideElements.includes('jugadores'))?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.jugadores.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.jugadores.single'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.jugadores.single'),"loading":_vm.loading,"search-input":_vm.search,"items":_vm.players,"item-text":"fullname","item-value":"id","error-messages":errors,"clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.item.jugador_id),callback:function ($$v) {_vm.$set(_vm.item, "jugador_id", $$v)},expression:"item.jugador_id"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.showSelectorTorneo && !_vm.hideElements.includes('torneos'))?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.torneos.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.torneos.single'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.torneos.single'),"loading":_vm.loading,"search-input":_vm.search,"items":_vm.players,"item-text":"fullname","item-value":"id","error-messages":errors,"clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.item.torneo_id),callback:function ($$v) {_vm.$set(_vm.item, "torneo_id", $$v)},expression:"item.torneo_id"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.showSelectorJornadas && !_vm.hideElements.includes('jornadas'))?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.jornadas.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.jornadas.single'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.jornadas.single'),"loading":_vm.loading,"search-input":_vm.search,"cache-items":"","items":_vm.players,"item-text":"fullname","item-value":"id","error-messages":errors,"clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.item.jornada_id),callback:function ($$v) {_vm.$set(_vm.item, "jornada_id", $$v)},expression:"item.jornada_id"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.showSelectorEquipos)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.equipos.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.equipos.single'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.equipos.single'),"loading":_vm.loading,"search-input":_vm.search,"cache-items":"","items":_vm.players,"item-text":"fullname","item-value":"id","error-messages":errors,"clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.item.equipo_id),callback:function ($$v) {_vm.$set(_vm.item, "equipo_id", $$v)},expression:"item.equipo_id"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.showSelectorJuegoInformal)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.juegos_informales.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.juegos_informales.single'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.juegos_informales.single'),"loading":_vm.loading,"search-input":_vm.search,"cache-items":"","items":_vm.players,"item-text":"fullname","item-value":"id","error-messages":errors,"clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.item.juego_informal_id),callback:function ($$v) {_vm.$set(_vm.item, "juego_informal_id", $$v)},expression:"item.juego_informal_id"}})]}}],null,true)})],1)],1)],1):_vm._e(),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.notes')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.notes'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"placeholder":_vm.$t('app.headers.notes'),"error-messages":errors},model:{value:(_vm.item.notas),callback:function ($$v) {_vm.$set(_vm.item, "notas", $$v)},expression:"item.notas"}})]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.price')))]),_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"display-3 text-right"},[_vm._v(" "+_vm._s(_vm.currentDivisa.symbol)+" "+_vm._s(_vm.finalPrice)+" "+_vm._s(_vm.currentDivisa.code)+" ")]),(_vm.discountAmount > 0)?_c('p',{staticClass:"display-2 text-right red--text"},[_vm._v(" - "+_vm._s(_vm.currentDivisa.symbol)+" "+_vm._s(_vm.currentDiscount)+" "+_vm._s(_vm.currentDivisa.code)+" ")]):_vm._e(),_c('hr'),_c('p',{staticClass:"display-4 text-right mt-2"},[_vm._v(" "+_vm._s(_vm.currentDivisa.symbol)+" "+_vm._s(_vm.totalPrice)+" "+_vm._s(_vm.currentDivisa.code)+" ")])]),(_vm.currentConcepto)?_c('v-card-actions',{staticClass:"text-right"},[_c('v-chip',{staticClass:"ma-1",attrs:{"active-class":"primary","large":""},on:{"click":function($event){return _vm.updatePriceDisplayed(_vm.divisaBase)}}},[_vm._v(" "+_vm._s(_vm.divisaBase.symbol)+" "+_vm._s(_vm.currentConcepto.costo)+" "+_vm._s(_vm.divisaBase.code)+" ")]),_vm._l((_vm.currentConcepto.conversiones),function(divisa,index){return _c('v-chip',{key:("divisa-conversion-" + index),staticClass:"ma-1",attrs:{"active-class":"primary","large":""},on:{"click":function($event){return _vm.updatePriceDisplayed(divisa)}}},[_vm._v(" "+_vm._s(divisa.currency_symbol)+" "+_vm._s(_vm.currencyConverter(_vm.currentConcepto.costo, divisa.factor, divisa.operation))+" "+_vm._s(divisa.currency_code)+" ")])})],2):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }