<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.pagos.rent_buy_equipment') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <validation-observer
              ref="observer"
              v-slot="{ validate, reset }"
            >
              <form>
                <v-list
                  three-line
                >
                  <v-list-item v-if="hideElements.length === 0">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.conceptos_pago.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.conceptos_pago.single')"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="item.concepto_id"
                            :placeholder="$t('app.conceptos_pago.single')"
                            :items="conceptos"
                            item-text="descripcion"
                            item-value="id"
                            :error-messages="errors"
                            required
                            clearable
                          ></v-autocomplete>
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="currentConcepto">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.headers.discount') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-text-field
                          class="mt-2"
                          :placeholder="$t('app.headers.discount')"
                          clearable
                          reverse
                          outlined
                          type="number"
                          v-model="discountAmount"
                        >
                          <template v-slot:append-outer>
                            <v-btn-toggle
                              v-model="discountType"
                              mandatory
                            >
                              <v-btn small active-class="primary">
                                $
                              </v-btn>
                              <v-btn small active-class="primary">
                                %
                              </v-btn>
                            </v-btn-toggle>
                          </template>
                        </v-text-field>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.formas_pago.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.formas_pago.single')"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="item.forma_pago_id"
                            :placeholder="$t('app.formas_pago.single')"
                            :items="formasPago"
                            item-text="nombre"
                            item-value="id"
                            :error-messages="errors"
                            required
                            clearable
                          ></v-autocomplete>
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="showSelectorJugadores && !hideElements.includes('jugadores')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.jugadores.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.jugadores.single')"
                          rules=""
                        >
                          <v-autocomplete
                            v-model="item.jugador_id"
                            :placeholder="$t('app.jugadores.single')"
                            :loading="loading"
                            :search-input.sync="search"
                            :items="players"
                            item-text="fullname"
                            item-value="id"
                            :error-messages="errors"
                            clearable
                          ></v-autocomplete>
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="showSelectorTorneo && !hideElements.includes('torneos')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.torneos.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.torneos.single')"
                          rules=""
                        >
                          <v-autocomplete
                            v-model="item.torneo_id"
                            :placeholder="$t('app.torneos.single')"
                            :loading="loading"
                            :search-input.sync="search"
                            :items="players"
                            item-text="fullname"
                            item-value="id"
                            :error-messages="errors"
                            clearable
                          ></v-autocomplete>
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="showSelectorJornadas && !hideElements.includes('jornadas')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.jornadas.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.jornadas.single')"
                          rules=""
                        >
                          <v-autocomplete
                            v-model="item.jornada_id"
                            :placeholder="$t('app.jornadas.single')"
                            :loading="loading"
                            :search-input.sync="search"
                            cache-items
                            :items="players"
                            item-text="fullname"
                            item-value="id"
                            :error-messages="errors"
                            clearable
                          ></v-autocomplete>
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="showSelectorEquipos">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.equipos.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.equipos.single')"
                          rules=""
                        >
                          <v-autocomplete
                            v-model="item.equipo_id"
                            :placeholder="$t('app.equipos.single')"
                            :loading="loading"
                            :search-input.sync="search"
                            cache-items
                            :items="players"
                            item-text="fullname"
                            item-value="id"
                            :error-messages="errors"
                            clearable
                          ></v-autocomplete>
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="showSelectorJuegoInformal">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.juegos_informales.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.juegos_informales.single')"
                          rules=""
                        >
                          <v-autocomplete
                            v-model="item.juego_informal_id"
                            :placeholder="$t('app.juegos_informales.single')"
                            :loading="loading"
                            :search-input.sync="search"
                            cache-items
                            :items="players"
                            item-text="fullname"
                            item-value="id"
                            :error-messages="errors"
                            clearable
                          ></v-autocomplete>
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.headers.notes') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.notes')"
                          rules=""
                        >
                          <v-textarea
                            v-model="item.notas"
                            :placeholder="$t('app.headers.notes')"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </form>
            </validation-observer>
          </v-col>
          <v-col cols="5">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.price') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col>
                        <v-card flat class="mx-auto">
                          <v-card-text>
                            <p class="display-3 text-right">
                              {{ currentDivisa.symbol }}
                              {{ finalPrice }}
                              {{ currentDivisa.code }}
                            </p>
                            <p class="display-2 text-right red--text"
                               v-if="discountAmount > 0">
                              -
                              {{ currentDivisa.symbol }}
                              {{ currentDiscount }}
                              {{ currentDivisa.code }}
                            </p>
                            <hr>
                            <p class="display-4 text-right mt-2">
                              {{ currentDivisa.symbol }}
                              {{ totalPrice }}
                              {{ currentDivisa.code }}
                            </p>
                          </v-card-text>
                          <v-card-actions class="text-right" v-if="currentConcepto">
                            <v-chip active-class="primary" large class="ma-1" @click="updatePriceDisplayed(divisaBase)">
                              {{ divisaBase.symbol }}
                              {{ currentConcepto.costo }}
                              {{ divisaBase.code }}
                            </v-chip>
                            <v-chip active-class="primary" large class="ma-1" :key="`divisa-conversion-${index}`" v-for="(divisa, index) in currentConcepto.conversiones" @click="updatePriceDisplayed(divisa)">
                              {{ divisa.currency_symbol }}
                              {{ currencyConverter(currentConcepto.costo, divisa.factor, divisa.operation) }}
                              {{ divisa.currency_code }}
                            </v-chip>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex'

  export default {
    name: 'PagoAdd',
    data () {
      return {
        dialog: false,
        item: {
          concepto_id: null,
          costo_neto: 0,
          descuento: 0,
          costo_final: 0,
          notas: null,
          divisa_id: null,
          forma_pago_id: null,
          jugador_id: null,
          jornada_id: null,
        },
        sucursales: [],
        datePickerModalStart: false,
        datePickerModalEnd: false,
        conceptos: [],
        discountType: undefined,
        discountAmount: 0,
        finalPrice: 0,
        formasPago: [],
        search: null,
        loading: false,
        players: [],
        currentDivisa: false,
        currentConversion: false,
        hideElements: [],
      }
    },
    computed: {
      ...mapState([
        'divisaBase',
      ]),
      totalPrice () {
        return this.finalPrice - this.currentDiscount
      },
      currentDiscount () {
        if (this.discountType) {
          switch (this.discountType) {
            case 0: return this.discountAmount
            case 1: return this.discountAmount * this.finalPrice / 100
          }
        } else {
          return this.discountAmount
        }
      },
      currentConcepto () {
        let concepto = this.conceptos.filter(item => item.id == this.item.concepto_id)
        if (concepto && concepto.length > 0) {
          let item = concepto[0]
          this.item.costo_neto = item.costo
          this.item.costo_final = item.costo
          this.item.divisa_id = item.divisa_base.id
          this.finalPrice = item.costo
          return item
        }
      },
      credencialConceptoId () {
        return parseInt(process.env.VUE_APP_CONCEPTO_PAGO_CREDENCIAL)
      },
      showSelectorJugadores () {
        const items = JSON.parse(process.env.VUE_APP_SHOW_SELECTOR_JUGADORES)
        if (items) {
          return items.includes(this.item.concepto_id)
        }
        return false
      },
      showSelectorEquipos () {
        const items = JSON.parse(process.env.VUE_APP_SHOW_SELECTOR_EQUIPOS)
        if (items) {
          return items.includes(this.item.concepto_id)
        }
        return false
      },
      showSelectorJornadas () {
        const items = JSON.parse(process.env.VUE_APP_SHOW_SELECTOR_JORNADAS)
        if (items) {
          return items.includes(this.item.concepto_id)
        }
        return false
      },
      showSelectorJuegoInformal () {
        const items = JSON.parse(process.env.VUE_APP_SHOW_SELECTOR_JUEGO_INFORMAL)
        if (items) {
          return items.includes(this.item.concepto_id)
        }
        return false
      },
      showSelectorTorneo () {
        const items = JSON.parse(process.env.VUE_APP_SHOW_SELECTOR_TORNEO)
        if (items) {
          return items.includes(this.item.concepto_id)
        }
        return false
      },
    },
    watch: {
      search (val) {
        val && val !== this.item.jugador_id && this.searchPlayer(val)
      },
    },
    mounted () {
      EventBus.$on('pago-add', async (params = false) => {
        this.toggleLoader()

        let conceptos = await this.getConceptoPago()
        this.formasPago = await this.getFormasPago()

        this.conceptos = conceptos.map(item => {
          item.descripcion = [
            item.descripcion,
            item.tipo_concepto.nombre,
            item.tipo_concepto.deporte.descripcion
          ].join(' - ')
          return item
        })

        this.currentDivisa = this.divisaBase
        this.currentConversion = {
          factor: 1,
          operation: '*',
        }

        if (params) {
          this.item.concepto_id = params.conceptoPagoId

          switch (params.conceptoPagoId) {
            case parseInt(process.env.VUE_APP_CONCEPTO_PAGO_CREDENCIAL) :
              this.item.jugador_id = params.item.id
              this.hideElements = ['jugadores']
            break

            case parseInt(process.env.VUE_APP_PAGO_ARBITRAJE_JORNADA) :
              this.item.jornada_id = params.item.id
              this.hideElements = ['jornadas', 'jugadores']
            break

            default: break
          }
        }

        this.toggleLoader()
        this.dialog = true
      })
    },
    methods: {
      updatePriceDisplayed (divisa) {
        const _divisa = divisa.hasOwnProperty('fromCurrency') ? divisa.fromCurrency : divisa
        this.currentConversion = divisa.hasOwnProperty('fromCurrency') ? divisa : {
          factor: 1,
          operation: '*',
        }

        this.currentDivisa = _divisa
        this.item.costo_neto = this.currentConcepto.costo
        this.item.divisa_id = this.currentConcepto.divisa_base.id

        if (this.currentConcepto.divisa_base.id !== _divisa.id) {
          this.finalPrice = this.currencyConverter(this.currentConcepto.costo, divisa.factor, divisa.operation)
        } else {
          this.finalPrice = this.currentConcepto.costo
        }
      },
      async searchPlayer (query) {
        this.loading = true

        this.players = await this.findPlayerWithoutCredential(query)

        this.loading = false
      },
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            let item = this.item
            item.descuento = this.currentDiscount
            item.costo_final = this.totalPrice
            item.divisa_id = this.currentDivisa.id
            item.divisa_base_id = this.currentConcepto.divisa_base.id
            item.factor_divisa = this.currentConversion.factor
            item.operacion_divisa = this.currentConversion.operation

            await this.$http.post(route('v1/payment'), item)
              .then(response => {
                if (response.body.code === 200) {
                  this.processSuccess(response)
                  this.item = {
                    concepto_id: null,
                    costo_neto: 0,
                    descuento: 0,
                    costo_final: 0,
                    notas: null,
                    divisa_id: null,
                    jugador_id: null,
                  }

                  this.players = false
                  this.currentConcepto = false
                  this.currentConversion = false
                  this.currentDivisa = false
                  this.finalPrice = 0
                  this.totalPrice = 0

                  this.discountAmount = 0
                  this.discountType = undefined

                  requestAnimationFrame(() => {
                    this.$refs.observer.reset()
                  })

                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
